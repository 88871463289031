export default function handleAccordionClick(e) {
  const isAccordionBtnAvalible = e.target.hasAttribute("data-accordion-btn");
  if (!isAccordionBtnAvalible) return;

  const accordionBtnRef = e.target;
  const accordionActiveBtnRef = e.currentTarget.querySelector('.active[data-accordion-btn]');

  if (accordionBtnRef === accordionActiveBtnRef) return setAccordionActive(accordionBtnRef);
  if (accordionActiveBtnRef) setAccordionActive(accordionActiveBtnRef);
  setAccordionActive(accordionBtnRef);
}

function setAccordionActive(accordionBtnRef) {
  const accordionBoxRef = accordionBtnRef?.closest("[data-accordion-box]");
  const accordionContentRef = accordionBoxRef?.querySelector( "[data-accordion-content]" );

  const expanded = accordionBtnRef.getAttribute("aria-expanded") === "true" || false;
  const hidden = accordionContentRef.getAttribute("aria-hidden") === "true" || false;

  accordionBtnRef?.classList.toggle("active");
  accordionBoxRef?.classList.toggle("active");
  accordionContentRef?.classList?.toggle("active");
  accordionContentRef.setAttribute("aria-hidden", !hidden);
  accordionBtnRef.setAttribute("aria-expanded", !expanded);
}
